<template>
  <div class="container">
    <div class="wrap">
      <span>
        <router-link class="item" active-class="active" :to="{name:'首页'}">首页</router-link>
      </span>
      <span>
        <router-link class="item" active-class="active" :to="{name:'我的卫命'}">我的卫命</router-link>
      </span>
      <span>
        <router-link class="item" active-class="active" :to="{name:'收件箱'}">收件箱</router-link>
      </span>  
      <span>
        <router-link class="item" active-class="active" :to="{name:'个人中心'}">个人中心</router-link>
      </span>
      <span>
        <router-link class="item" active-class="active" :to="{name:'搜询医生'}">搜询医生</router-link>
      </span>
    <!--  <span>
        <router-link class="item" active-class="active" :to="{name:'商贸中心'}">商贸中心</router-link>
      </span>
      <span>
        <router-link class="item" active-class="active" :to="{name:'资讯'}">资讯</router-link>
      </span> -->
     <span>
        <router-link class="item" active-class="active" :to="{name:'学习科普'}">学习科普</router-link>
      </span>
      <span>
        <router-link class="item" active-class="active" :to="{name:'招聘求职'}">招聘求职</router-link>
      </span>
      <span>
        <router-link class="item" active-class="active" :to="{name:'海外合作'}">海外合作</router-link>
      </span>
	  
	 <span>
	    <router-link class="item" active-class="active" :to="{name:'关于我们'}">关于我们</router-link>
	  </span>
      <span>
        <router-link class="item" active-class="active" :to="{name:'联系客服'}"> 联系客服</router-link>
      </span>
    </div>
  </div>
</template>

<script>
  export default {
    name: "NavBar"
  }
</script>

<style scoped>
.container{
  width: 100%;
  line-height: 40px;
  height: 40px;
  background-color: #fff;
}
  .wrap{
    width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .item{
    text-decoration: none;
    color: #3B383B;
    font-size: 18px;
  }
  .active{
    text-decoration: none;
    color: #51CBDF;
  }
</style>
